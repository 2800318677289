<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-tabs
            pills
            fill
          >
            <b-tab active>
              <template
                #title
              >
                <feather-icon icon="MessageCircleIcon" />
                <span>Yeni Görüşmeler</span>
              </template>
              <b-row class="mt-2">
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="Marka"
                    label-for="id_com_brand"
                  >
                    <v-select
                      id="id_com_brand"
                      v-model="id_com_brand"
                      label="title"
                      :options="brands"
                      :reduce="brand => brand.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="Temas Tipi"
                    label-for="id_com_meet"
                  >
                    <v-select
                      id="id_com_meet"
                      v-model="id_com_meet"
                      label="title"
                      :options="meets"
                      :reduce="meet => meet.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="Danışman"
                    label-for="id_com_user"
                  >
                    <v-select
                      id="id_com_user"
                      v-model="id_com_user"
                      label="title"
                      :options="users"
                      :reduce="user => user.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="KVKK Durum"
                    label-for="kvkk"
                  >
                    <v-select
                      id="kvkk"
                      v-model="kvkk"
                      label="title"
                      :options="kvkkStatuses"
                      :reduce="item => item.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="6"
                >
                  <b-form-group
                    label="Ay"
                    label-for="month"
                  >
                    <v-select
                      id="month"
                      v-model="month"
                      label="title"
                      :options="months"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="6"
                >
                  <b-form-group
                    label="Yıl"
                    label-for="year"
                  >
                    <v-select
                      id="year"
                      v-model="year"
                      label="title"
                      :options="years"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group class="text-center">
                    <b-button
                      variant="primary"
                      @click="getReport"
                    >
                      <FeatherIcon icon="BarChartIcon" />
                      <span class="align-middle">Raporu Görüntüle</span>
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <interviews-table />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="RepeatIcon" />
                <span>Tüm Görüşmeler</span>
              </template>
              <b-row class="mt-2">
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="Marka"
                    label-for="id_com_brand2"
                  >
                    <v-select
                      id="id_com_brand2"
                      v-model="id_com_brand2"
                      label="title"
                      :options="brands"
                      :reduce="brand => brand.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="Temas Tipi"
                    label-for="id_com_meet2"
                  >
                    <v-select
                      id="id_com_meet2"
                      v-model="id_com_meet2"
                      label="title"
                      :options="meets"
                      :reduce="meet => meet.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="Danışman"
                    label-for="id_com_user2"
                  >
                    <v-select
                      id="id_com_user2"
                      v-model="id_com_user2"
                      label="title"
                      :options="users"
                      :reduce="user => user.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="3"
                >
                  <b-form-group
                    label="KVKK Durum"
                    label-for="kvkk2"
                  >
                    <v-select
                      id="kvkk2"
                      v-model="kvkk2"
                      label="title"
                      :options="kvkkStatuses"
                      :reduce="item => item.id"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="6"
                >
                  <b-form-group
                    label="Ay"
                    label-for="month2"
                  >
                    <v-select
                      id="month2"
                      v-model="month2"
                      label="title"
                      :options="months"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  xs="12"
                  md="6"
                >
                  <b-form-group
                    label="Yıl"
                    label-for="year2"
                  >
                    <v-select
                      id="year2"
                      v-model="year2"
                      label="title"
                      :options="years"
                      placeholder="Seçiniz"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group class="text-center">
                    <b-button
                      variant="primary"
                      @click="getMeetReport"
                    >
                      <FeatherIcon icon="BarChartIcon" />
                      <span class="align-middle">Raporu Görüntüle</span>
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <meets-table />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import InterviewsTable from '@/views/Reports/Sales/InterviewsReport/Interviews.vue'
import MeetsTable from '@/views/Reports/Sales/InterviewsReport/Meets.vue'

export default {
  name: 'InterviewsReport',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BTabs,
    BTab,
    vSelect,
    InterviewsTable,
    MeetsTable,
  },
  data() {
    return {
      id_com_user: null,
      id_com_meet: null,
      id_com_brand: null,
      kvkk: null,
      year: moment().format('y'),
      month: moment().format('M'),
      id_com_user2: null,
      id_com_meet2: null,
      id_com_brand2: null,
      kvkk2: null,
      year2: moment().format('y'),
      month2: moment().format('M'),
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      kvkkStatuses: [
        {
          id: '1',
          title: 'Onaylı',
        },
        {
          id: '0',
          title: 'Onaysız',
        },
      ],
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
    years() {
      return this.$store.getters['salesInterviews/getInterviewYears']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    this.getYears()
    this.getUsers()
    this.getReport()
    this.getMeetReport()
    this.getMeets()
    this.getBrands()
  },
  methods: {
    getReport() {
      this.$store.dispatch('salesInterviews/interviewsReport', {
        month: this.month,
        year: this.year,
        id_com_user: this.id_com_user,
        id_com_meet: this.id_com_meet,
        id_com_brand: this.id_com_brand,
        kvkk: this.kvkk,
      })
    },
    getMeetReport() {
      this.$store.dispatch('salesInterviews/meetReport', {
        month: this.month2,
        year: this.year2,
        id_com_user: this.id_com_user2,
        id_com_meet: this.id_com_meet2,
        id_com_brand: this.id_com_brand2,
        kvkk: this.kvkk2,
      })
    },
    getYears() {
      this.$store.dispatch('salesInterviews/interviewYears')
    },
    getMeets() {
      this.$store.dispatch('meets/meetsList', {
        select: ['com_meet.id AS id', 'com_meet.title AS title'],
        where: {
          type: 1,
        },
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          salestatus: 1,
        },
      })
    },
    getUsers() {
      const params = {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 2,
        },
        where_in: [
          { 0: 'com_user.id_com_brand', 1: [1, 2, 3, 4, 5, 13] },
        ],
      }
      this.$store.dispatch('users/usersList', params)
    },
  },
}
</script>

<style scoped></style>
